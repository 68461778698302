/* Container for the form */
form {
    max-width: 400px;
    margin: 50px auto; /* Added margin-top for spacing */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

/* Style for each form group */
form div {
    margin-bottom: 15px;
}

/* Style for labels */
label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

/* Style for inputs */
input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

/* Style for the submit button */
button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.create-account-link {
    text-align: center;
    margin-top: 20px;
}

.create-account-link button {
    background: none;
    border: none;
    color: #007BFF;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.create-account-link button:hover {
    background-color: #ccc;
    text-decoration: underline;
}