.logout-profile-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
}
.profile-icon {
    position: relative;
    margin-right: 20px;
    cursor: pointer;
}
.profile-icon img {
    width: 30px;
    height: 30px;
}
.greeting {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 2px 5px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
}
.logout-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
.profile-menu {
    position: absolute;
    top: 35px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.menu-item {
    padding: 5px 10px;
    cursor: pointer;
}
.menu-item.username {
    cursor: default;
}
.menu-item:hover {
    background-color: #f0f0f0;
}
hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 5px 0;
}