.continue-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    text-align: left;
}
.continue-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}
.continue-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
}
.continue-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.continue-option {
    padding: 15px;
    border-radius: 8px;
    border: 2px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}
.continue-option:hover {
    border-radius: 100px;
}
.continue-option input {
    margin-right: 10px;
    cursor: pointer;
}
.continue-option label {
    cursor: pointer;
    font-size: 16px;
}
.continue-now {
    background-color: #e6f4e8;
    border-color: #28a745;
}
.continue-later {
    background-color: #fff8e6;
    border-color: #ffc107;
}
.conclude-study {
    background-color: #fbe6e6;
    border-color: #dc3545;
}
.continue-option label {
    font-weight: normal; /* Ensure unselected labels are not bold */
}
.selected label {
    font-weight: bold;
}
.option-title {
    font-size: 18px; /* Slightly larger font size */
}
.submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}
.submit-button:hover {
    background-color: #0056b3;
}