.debate-history-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.debate-history-item {
    margin-bottom: 10px; /* Add space between items */
}

.debate-history-container {
    height: 400px; /* or any fixed height */
    overflow-y: auto;
}