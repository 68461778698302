.container {
    margin: 0 auto;
    margin-top: 10px;
    max-width: 800px;
    padding: 20px;
}

.consent-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
}

.consent-actions {
    margin-top: 10px;
    text-align: center;
}

.consent-actions label {
    display: block;
    margin-bottom: 10px;
}

.consent-actions button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.consent-actions button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.response-message {
    background-color: #e0f7fa;
    border: 1px solid #00796b;
    color: #004d40;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 16px;
}

.checkbox-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.label-unbold {
    opacity: 0.5;
}

.label-bold {
    opacity: 1;
}