.likert-labels {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.likert-label {
	width: 14.28%; /* 100% / 7 labels */
	text-align: center;
	cursor: pointer; /* Add cursor pointer to indicate clickable labels */
}

.likert-label.selected {
	font-weight: bold;
}

.range-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

.range-container input[type="range"] {
	width: 100%;
}

.submit-button {
	margin-top: 50px; /* Add margin to separate from Likert scale */
}

.content-wrapper {
	display: flex;
	align-items: stretch;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px; /* Adjust padding as needed */    
} 