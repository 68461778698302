.debate-input-container {
    position: relative;
    width: 100%;
}

.large-textarea {
    width: 100%;
    height: 300px; /* Adjust the height as needed */
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
}

.word-count {
    font-size: 18px;
    color: #555;
    margin-right: 10px;
}

.action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
}

.action-container > .word-count {
    position: absolute;
    left: 0;
}

.action-container > button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.action-container > button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}