.results-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    border: 2px dotted #000;
    border-radius: 8px;
}

.results-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-decoration: underline;
}

.results-placeholder {
    text-align: center;
    padding: 50px;
    border: 2px dashed #ccc;
    color: #777;
}

.result-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 16px;
}

.error-message {
    color: red;
}

.center-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.topic-container {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.topic-title {
    font-size: 1.5em;
    font-weight: bold;
}

.sides-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.side {
    flex: 1;
    padding: 10px;
    position: relative;
}

.user-side {
    border-right: 1px solid #ccc;
}

.rating-container {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    margin-bottom: 10px;
}

.rating-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    font-weight: bold;
    border: 3px solid black;
    margin-top: 10px;
}

.rating-a { background-color: #00ff00; } /* Bright green */
.rating-b { background-color: #7fff00; }
.rating-c { background-color: #ffff00; }
.rating-d { background-color: #ffbf00; }
.rating-e { background-color: #ff8000; }
.rating-f { background-color: #ff0000; } /* Red */

.feedback-container {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-container h3 {
    margin-bottom: 10px;
}

.feedback-container hr {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 10px 0;
}

.status-approved {
    background-color: #e6f4e8;
    border-color: #28a745;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    color: #28a745;
}

.status-awaiting-review {
    background-color: #fff8e6;
    border-color: #ffc107;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    color: #ffc107;
}

.status-error {
    background-color: #fbe6e6;
    border-color: #dc3545;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    color: #dc3545;
}

.participant-status {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
}